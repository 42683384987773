import club2 from './club-2.jpg';
import club2_thumb from './club-2_thumb.jpg';
import club3 from './club-3.jpg';
import club3_thumb from './club-3_thumb.jpg';
import club4 from './club-4.jpg';
import club4_thumb from './club-4_thumb.jpg';
import gallery1 from './gallery-1.jpg';
import gallery1_thumb from './gallery-1_thumb.jpg';
import gallery2 from './gallery-2.jpg';
import gallery2_thumb from './gallery-2_thumb.jpg';

const images = [
  {
    id: 'club-image-3',
    src: club3_thumb,
    width: 512,
    height: 387,
    srcSet: [
      {
        src: club3_thumb,
        width: 512,
        height: 387,
      },
      {
        src: club3,
        width: 2048,
        height: 1546,
      },
    ]
  },
  {
    id: 'club-image-4',
    src: club4_thumb,
    width: 512,
    height: 387,
    srcSet: [
      {
        src: club4_thumb,
        width: 512,
        height: 387,
      },
      {
        src: club4,
        width: 2048,
        height: 1546,
      },
    ]
  },
  {
    id: 'club-image-2',
    src: club2_thumb,
    width: 512,
    height: 387,
    srcSet: [
      {
        src: club2_thumb,
        width: 512,
        height: 387,
      },
      {
        src: club2,
        width: 2048,
        height: 1546,
      },
    ]
  },
  {
    id: 'gallery-image-1',
    src: gallery1_thumb,
    width: 512,
    height: 341,
    srcSet: [
      {
        src: gallery1_thumb,
        width: 512,
        height: 341,
      },
      {
        src: gallery1,
        width: 2048,
        height: 1365,
      },
    ]
  },
  {
    id: 'gallery-image-2',
    src: gallery2_thumb,
    width: 512,
    height: 341,
    srcSet: [
      {
        src: gallery2_thumb,
        width: 512,
        height: 341,
      },
      {
        src: gallery2,
        width: 2048,
        height: 1365,
      },
    ]
  },
];

export default images;